<template>
  <div class="row m-0 justify-content-center">
    <div class="col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4 p-0 mt-5 mb-3">
      <InitialForm @continue="isShareRewardPopup = true" v-model="baseAmount" />
    </div>
    <transition name="fade">
      <ShareRewardPopup v-if="isShareRewardPopup" @close="isShareRewardPopup = false" @continue="showSadaqahWizard = true" :baseAmount="baseAmount" v-model="totalDailySadaqah" />
    </transition>
    <DailySadaqahWizard
      v-if="showSadaqahWizard"
      v-model:paymentMethod="paymentMethod"
      v-model:bankAccountDetails="bankAccountDetails"
      v-model:paymentFrequencyDetails="paymentFrequencyDetails"
      v-model:reference="reference"
      v-model:anonymous="anonymous"
      v-model:donatingAs="donatingAs"
      @closeWizard="showSadaqahWizard = false"
      @save="save"
      :sadaqahUserDonatingType="sadaqahUserDonatingType"
      :isLoading="isLoading"
      :totalDailySadaqah="totalDailySadaqah"
    />
    <transition name="fade">
      <CompletePopup v-if="isCompleted" />
    </transition>
    <transition name="fade">
      <Popup v-if="tooSmall" @close="closeTooSmall">
        <div class="row mt-4 justify-content-center text-center">
          <div class="col-7 col-md-6 text-center">
            <ExclamationAnimation />
          </div>
          <div class="col-12 mb-3 text-center medium font23 green-text">
            Error
          </div>
          <div class="col-10 mb-3 bigger_font text-center medium green-text">
            The minimum donation total is <b>R5.00</b>
          </div>
          <div class="col-10 mb-3 text-center font15">
            Please increase your donation so the total is at least <b>R5.00</b>
          </div>
        </div>
      </Popup>
    </transition>
    <transition name="fade">
      <TurnstilePopup v-if="isTurnstile" @close="closeTurnstile" @verify="verifyTurnstile"/>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    InitialForm: defineAsyncComponent(() => import('./views/InitialForm.vue')),
    DailySadaqahWizard: defineAsyncComponent(() => import('./views/wizard/DailySadaqahWizard.vue')),
    ShareRewardPopup: defineAsyncComponent(() => import('./views/ShareRewardPopup.vue')),
    CompletePopup: defineAsyncComponent(() => import('./views/CompletePopup.vue')),
    ExclamationAnimation: defineAsyncComponent(() => import('@/components/animations/ExclamationAnimation.vue')),
    Popup: defineAsyncComponent(() => import('@/views/Popup.vue')),
    TurnstilePopup: defineAsyncComponent(() => import('@/views/TurnstilePopup.vue'))

  },
  name: 'Daily Sadaqah Setup',
  data () {
    return {
      isShareRewardPopup: false,
      baseAmount: 0,
      showSadaqahWizard: false,
      isLoading: false,
      isCompleted: false,
      totalDailySadaqah: null,
      paymentMethod: '',
      reference: '',
      bankAccountDetails: {
        bankName: '',
        accountHolderName: '',
        branchCode: '',
        accountNumber: ''
      },
      paymentFrequencyDetails: {
        debitOrderDay: 0,
        debitOrderMonth: null,
        startDate: 0,
        frequency: ''
      },
      anonymous: {
        donorType: 'individual',
        organisationName: null,
        donorName: null,
        donorSurname: null,
        cellNumber: null,
        email: null,
        address: {
          addressLine1: null,
          town: null,
          country: null,
          postalCode: null
        },
        isValidNumber: true,
        isCreateDonor: false,
        password: null
      },
      donatingAs: {
        userID: '',
        organisationID: '',
        displayID: '',
        displayName: '',
        address: {
          addressLine1: null,
          town: null,
          country: null,
          postalCode: null
        }
      },
      theSadaqah: {},
      isTurnstile: false,
      turnstileReturn: null,
      tooSmall: false
    }
  },
  computed: {
    ...mapGetters([
      'user', 'donateAs'
    ]),
    sadaqahUserDonatingType () {
      if (this.donateAs) {
        if (this.donateAs.groupID) {
          return 'group'
        } else if (this.user.organisationID) {
          return 'organisation'
        } else {
          return 'individual'
        }
      } else if (this.user) {
        return 'individual'
      } else {
        return this.anonymous.donorType
      }
    }
  },
  methods: {
    ...mapActions([
      'saveSadaqah',
      'saveRecurringDonation',
      'initiatePayfast'
    ]),
    async save () {
      this.isLoading = true
      if (this.paymentMethod === 'CREDIT_CARD') {
        await this.checkTurnstile()
      } else {
        await this.saveDonation()
      }
    },
    closeTurnstile () {
      this.turnstileReturn = null
      this.isTurnstile = false
      this.isLoading = false
    },
    verifyTurnstile (val) {
      this.turnstileReturn = val
      this.isTurnstile = false
      if (this.turnstileReturn) {
        this.saveDonation()
      }
    },
    checkTurnstile () {
      if (this.paymentMethod === 'CREDIT_CARD' && this.totalDailySadaqah < 5) {
        this.tooSmall = true
      } else {
        this.isTurnstile = true
      }
    },
    async saveDonation () {
      this.isLoading = true
      await this.getDonationDetails()
      if (this.user) {
        const ret = await this.saveRecurringDonation(this.theSadaqah)
        if (ret) {
          console.log('RET', ret)
          if (ret.data.recurringType === 'CREDIT_CARD') {
            console.log('HEREEEE', ret.data.subscriptionReference, this.turnstileReturn)
            const postData = {
              transactionRef: ret.data.subscriptionReference,
              recurringDonationID: ret.data.recurringDonationID,
              token: this.turnstileReturn
            }
            console.log('HEREEEE postData', postData)
            const returnDetails = await this.initiatePayfast(postData)
            if (returnDetails) {
              const myData = []
              // Merchant details
              myData.merchant_id = parseInt(returnDetails.merchant_id)
              myData.merchant_key = returnDetails.merchant_key
              myData.return_url = returnDetails.return_url
              myData.cancel_url = returnDetails.cancel_url
              myData.notify_url = returnDetails.notify_url
              // Transaction details
              myData.m_payment_id = returnDetails.m_payment_id
              myData.amount = parseFloat(returnDetails.amount).toFixed(2)
              myData.item_name = returnDetails.item_name
              if (returnDetails.email_address) {
                myData.email_address = returnDetails.email_address
              }
              myData.email_confirmation = returnDetails.email_confirmation
              myData.payment_method = returnDetails.payment_method
              myData.subscription_type = returnDetails.subscription_type
              myData.billing_date = returnDetails.billing_date
              myData.recurring_amount = returnDetails.recurring_amount
              myData.frequency = returnDetails.frequency
              myData.cycles = returnDetails.cycles

              // Generate signature
              myData.signature = returnDetails.signature
              var form = document.createElement('form')
              form.setAttribute('method', 'post')
              form.setAttribute('action', returnDetails.redirect.url)
              for (const key in myData) {
                if (Object.prototype.hasOwnProperty.call(myData, key)) {
                  const val = myData[key]
                  if (val !== '') {
                    var ele = document.createElement('input')
                    ele.setAttribute('value', val)
                    ele.setAttribute('name', key)
                    // ele.value = val
                    // ele.name = key
                    form.appendChild(ele)
                  }
                }
              }
              document.body.appendChild(form)
              form.submit()
            }
          } else {
            this.isLoading = false
            this.isCompleted = true
          }
        }
      } else {
        try {
          const ret = await this.saveSadaqah(this.theSadaqah)
          if (ret) {
            this.isShareRewardPopup = false
            this.showSadaqahWizard = false
            if (ret.status === 200) {
              this.isCompleted = true
            }
            this.isLoading = false
          }
        } catch (error) {
          console.error('ERRR', error)
        }
      }
      this.isLoading = false
    },
    getDonationDetails () {
      if (this.user) {
        if (this.paymentMethod === 'DEBIT_ORDER') {
          this.theSadaqah = {
            donorBankAccount: {
              bankName: this.bankAccountDetails.bankName,
              accountHolderName: this.bankAccountDetails.accountHolderName,
              accountNumber: this.bankAccountDetails.accountNumber,
              branchCode: this.bankAccountDetails.branchCode
            },
            startDate: this.paymentFrequencyDetails.startDate,
            frequency: this.paymentFrequencyDetails.frequency,
            reference: this.reference,
            debitOrderDay: this.paymentFrequencyDetails.debitOrderDay,
            dailySadaqaDonation: true,
            recurringDonationType: this.paymentMethod,
            totalDailySadaqah: this.totalDailySadaqah,
            transactionDTO: {
              userID: this.donatingAs.userID,
              organisationID: this.donatingAs.organisationID
            }
          }
        } else {
          this.theSadaqah = {
            startDate: this.paymentFrequencyDetails.startDate,
            frequency: this.paymentFrequencyDetails.frequency,
            debitOrderDay: this.paymentFrequencyDetails.debitOrderDay,
            dailySadaqaDonation: true,
            untilCancelled: true,
            recurringDonationType: this.paymentMethod,
            totalDailySadaqah: this.totalDailySadaqah,
            numberOfPayments: 0,
            transactionDTO: {
              userID: this.donatingAs.userID,
              organisationID: this.donatingAs.organisationID
            }
          }
        }
      } else {
        this.theSadaqah = {
          organisationName: this.anonymous.organisationName,
          donorName: this.anonymous.donorName,
          donorSurname: this.anonymous.donorSurname,
          cellNumber: this.anonymous.cellNumber,
          email: this.anonymous.email,
          address: this.anonymous.address,
          debitOrderDay: this.paymentFrequencyDetails.debitOrderDay,
          debitOrderMonth: this.paymentFrequencyDetails.debitOrderMonth,
          bankAccountDetails: {
            bankName: this.bankAccountDetails.bankName,
            accountHolderName: this.bankAccountDetails.accountHolderName,
            accountNumber: this.bankAccountDetails.accountNumber,
            reference: this.reference,
            branchCode: this.bankAccountDetails.branchCode
          },
          totalDailySadaqah: this.totalDailySadaqah
        }
      }
    },
    closeTooSmall () {
      this.tooSmall = false
      this.isLoading = false
    }
  }
}
</script>
